import { Type } from 'class-transformer'

import {
  PositionType,
  ValidatedNewDailyPowerMarketIndication as IValidatedNewDailyPowerMarketIndication,
  ValidateNewDailyPowerMarketIndicationsResult as IValidateNewDailyPowerMarketIndicationsResult,
} from '@/api/generated'
import { ValidateNewDailyPowerMarketIndicationsError } from '@/models/trading/ValidateNewDailyPowerMarketIndicationsError'

export class ValidatedNewDailyPowerMarketIndication
  implements IValidatedNewDailyPowerMarketIndication {
  organizationName!: string
  traderName?: string | undefined
  position!: PositionType
  area!: string
  deliveryPeriod!: string
  hourType!: string
  productType!: string
  volume!: string
  unitPrice!: string
}

export class ValidateNewDailyPowerMarketIndicationsResult
  implements IValidateNewDailyPowerMarketIndicationsResult {
  isValid!: boolean

  @Type(() => ValidatedNewDailyPowerMarketIndication)
  validated!: Array<ValidatedNewDailyPowerMarketIndication>

  @Type(() => ValidateNewDailyPowerMarketIndicationsError)
  errors!: Array<ValidateNewDailyPowerMarketIndicationsError>

  isEClearIncluded!: boolean
}
