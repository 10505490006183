import { RouteRecordRaw } from 'vue-router'

import { dealsRoutes } from '@/router/routes/broker/deals'
import { eClearOrganizationsRoutes } from '@/router/routes/broker/eClearOrganizations'
import { eCloudOrganizationsRoutes } from '@/router/routes/broker/eCloudOrganizations'
import { indicationsRoutes } from '@/router/routes/broker/indications'
import { negotiationsRoutes } from '@/router/routes/broker/negotiations'
import { organizationsRoutes } from '@/router/routes/broker/organizations'
import { reportsRoutes } from '@/router/routes/broker/reports'
import { salesRoutes } from '@/router/routes/broker/sales'
import BannerSetting from '@/views/BannerSetting.vue'
import Broking from '@/views/Broking.vue'
import BrokingDailyPowerMarket from '@/views/dailyPowerMarket/BrokingDailyPowerMarket.vue'
import EEXPostings from '@/views/trading/EEXPostings.vue'

export const brokerRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Broking',
    component: Broking,
  },
  {
    path: '/daily-power-market',
    name: 'DailyPowerMarket',
    component: BrokingDailyPowerMarket,
  },
  {
    path: '/eex-postings',
    name: 'EEXPostings',
    component: EEXPostings,
  },
  {
    path: '/banner-setting',
    name: 'BannerSetting',
    component: BannerSetting,
  },
  ...negotiationsRoutes,
  ...dealsRoutes,
  ...eClearOrganizationsRoutes,
  ...eCloudOrganizationsRoutes,
  ...organizationsRoutes,
  ...salesRoutes,
  ...reportsRoutes,
  ...indicationsRoutes,
]
