
import { defineComponent, PropType } from 'vue'

import BaseCollapsedNavigation from '@/components/common/CollapsedNavigation/BaseCollapsedNavigation.vue'
import { NavigationManager } from '@/components/common/constants/NavigationManager'
import { BaseCollapsedNavigationItem } from '@/components/common/interface/CollapsedNavigation/BaseCollapsedNavigationProps'

type StringOrUndefined = PropType<string | undefined>

export default defineComponent({
  name: 'TraderCollapsedNavigation',
  components: {
    BaseCollapsedNavigation,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    currentMenu: {
      type: String,
      required: true,
    },
    organizationName: {
      type: String as StringOrUndefined,
      default: undefined,
    },
    organizationPicture: {
      type: String as StringOrUndefined,
      default: undefined,
    },
    toggle: {
      type: Function,
      required: true,
    },
  },
  computed: {
    navItems(): BaseCollapsedNavigationItem[] {
      const navigationManager = new NavigationManager()

      return [
        {
          icon: 'exchange-alt',
          isCurrent: this.currentMenu === 'order',
          label: this.$t('common.label.order').toString(),
          categories: [
            // 通常注文
            {
              to: '/',
              label: this.$t('common.label.normalOrder').toString(),
            },
            // enechain Daily Power Market一括注文
            {
              to: '/daily-power-market',
              label: this.$t('common.label.dailyPowerMarket').toString(),
            },
          ],
        },
        {
          ...navigationManager.report,
          isCurrent: this.currentMenu === 'reports',
        },
        {
          icon: 'history',
          isCurrent: this.currentMenu === 'history',
          label: this.$t('common.label.history').toString(),
          categories: [
            // eSquare履歴
            {
              to: '/history',
              label: this.$t('common.label.history').toString(),
            },
          ],
        },
        {
          ...navigationManager.chat,
          isCurrent: this.currentMenu === 'chat',
        },
      ]
    },
  },
})
