
import { defineComponent, PropType } from 'vue'

import BaseCollapsedNavigation from '@/components/common/CollapsedNavigation/BaseCollapsedNavigation.vue'
import { NavigationManager } from '@/components/common/constants/NavigationManager'
import { BaseCollapsedNavigationItem } from '@/components/common/interface/CollapsedNavigation/BaseCollapsedNavigationProps'

type StringOrUndefined = PropType<string | undefined>

export default defineComponent({
  name: 'BrokerCollapsedNavigation',
  components: {
    BaseCollapsedNavigation,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    currentMenu: {
      type: String,
      required: true,
    },
    organizationName: {
      type: String as StringOrUndefined,
      default: undefined,
    },
    organizationPicture: {
      type: String as StringOrUndefined,
      default: undefined,
    },
    toggle: {
      type: Function,
      required: true,
    },
  },
  computed: {
    navItems(): BaseCollapsedNavigationItem[] {
      const navigationManager = new NavigationManager()

      return [
        {
          to: '/',
          icon: 'handshake',
          isCurrent: this.currentMenu === 'broking',
          label: this.$t('common.label.broking').toString(),
        },
        {
          icon: 'exchange-alt',
          isCurrent: this.currentMenu === 'trading',
          label: this.$t('common.label.trading').toString(),
          categories: [
            // 注文
            {
              to: '/indications',
              label: this.$t('common.label.indications').toString(),
            },
            // enechain Daily Power Market一括注文
            {
              to: '/daily-power-market',
              label: this.$t('common.label.dailyPowerMarket').toString(),
            },
            // 交渉
            {
              to: '/negotiations',
              label: this.$t('common.label.negotiations').toString(),
            },
            // 約定
            {
              to: '/deals',
              label: this.$t('common.label.deals').toString(),
            },
          ],
        },
        {
          to: '/eex-postings',
          icon: 'inbox',
          isCurrent: this.currentMenu === 'eexPostings',
          label: this.$t('common.label.eexPostings').toString(),
        },
        {
          ...navigationManager.report,
          isCurrent: this.currentMenu === 'reports',
        },
        {
          ...navigationManager.chat,
          isCurrent: this.currentMenu === 'chat',
        },
        {
          icon: 'chart-bar',
          isCurrent: this.currentMenu === 'sales',
          label: this.$t('common.label.enecAnalytics').toString(),
          categories: [
            // Kpler
            {
              label: this.$t('sales.label.kpler').toString(),
              items: [
                // スポット取引
                {
                  to: '/sales/kpler/spot-trade',
                  label: this.$t('sales.label.spotTrade').toString(),
                },
                // LNG船空き状況
                {
                  to: '/sales/kpler/vessel-availability',
                  label: this.$t('sales.label.vesselAvailability').toString(),
                },
                // LNGプラント停止情報
                {
                  to: '/sales/kpler/lng-plant-outage',
                  label: this.$t('sales.label.lngPlantOutage').toString(),
                },
              ],
            },
            // Sales KPI
            {
              label: this.$t('sales.label.salesKPI').toString(),
              items: [
                // Google Analytics
                {
                  href:
                    'https://analytics.google.com/analytics/web/#/p290457895',
                  isExternal: true,
                  label: this.$t('sales.label.ga').toString(),
                },
                // ウォレットシェア
                {
                  to: '/sales/sales-kpi/wallet-share',
                  label: this.$t('sales.label.walletShare').toString(),
                },
                // 営業戦略
                {
                  to: '/sales/sales-kpi/brokerage-strategy',
                  label: this.$t('sales.label.brokerageStrategy').toString(),
                },
                // アクセス履歴
                {
                  to: '/sales/sales-kpi/request-counts',
                  label: this.$t('sales.label.requestCounts').toString(),
                },
                // サインアップ状況
                {
                  to: '/sales/sales-kpi/active-users',
                  label: this.$t('sales.label.activeUsers').toString(),
                },
              ],
            },
          ],
        },
        {
          to: '/ecloud-organizations',
          icon: 'cloud',
          isCurrent: this.currentMenu === 'ecloud-organizations',
          label: this.$t('common.label.ecloudOrganizations').toString(),
        },
        {
          to: '/organizations',
          icon: 'building',
          isCurrent: this.currentMenu === 'organizations',
          label: this.$t('common.label.organizations').toString(),
        },
        {
          to: '/eclear-organizations',
          icon: 'shield-check',
          isCurrent: this.currentMenu === 'eclear-organizations',
          label: this.$t('common.label.eclearOrganizations').toString(),
        },
      ]
    },
  },
})
