import { Type } from 'class-transformer'

import {
  PositionType,
  ValidateNewMyDailyPowerMarketIndicationsResult as IValidateNewMyDailyPowerMarketIndicationsResult,
  ValidatedNewMyDailyPowerMarketIndication as IValidatedNewMyDailyPowerMarketIndication,
} from '@/api/generated'
import { ValidateNewDailyPowerMarketIndicationsError } from '@/models/trading/ValidateNewDailyPowerMarketIndicationsError'

export class ValidatedNewMyDailyPowerMarketIndication
  implements IValidatedNewMyDailyPowerMarketIndication {
  position!: PositionType
  area!: string
  deliveryPeriod!: string
  hourType!: string
  productType!: string
  volume!: string
  unitPrice!: string
}

export class ValidateNewMyDailyPowerMarketIndicationsResult
  implements IValidateNewMyDailyPowerMarketIndicationsResult {
  isValid!: boolean

  @Type(() => ValidatedNewMyDailyPowerMarketIndication)
  validated!: Array<ValidatedNewMyDailyPowerMarketIndication>

  @Type(() => ValidateNewDailyPowerMarketIndicationsError)
  errors!: Array<ValidateNewDailyPowerMarketIndicationsError>

  isEClearIncluded!: boolean
}
