<template>
  <div class="daily-power-market-indication-header">
    <div class="daily-power-market-indication-header__position-space" />
    <p class="daily-power-market-indication-header__item">
      {{ $t('dailyPowerMarket.confirm.indication.area') }}
    </p>
    <p class="daily-power-market-indication-header__item">
      {{ $t('dailyPowerMarket.confirm.indication.deliveryPeriod') }}
    </p>
    <p class="daily-power-market-indication-header__item">
      {{ $t('dailyPowerMarket.confirm.indication.hourType') }}
    </p>
    <p class="daily-power-market-indication-header__item">
      {{ $t('dailyPowerMarket.confirm.indication.productType') }}
    </p>
    <p class="daily-power-market-indication-header__item">
      {{ $t('dailyPowerMarket.confirm.indication.unitPrice') }}
    </p>
    <p class="daily-power-market-indication-header__item">
      {{ $t('dailyPowerMarket.confirm.indication.volume') }}
    </p>
  </div>
</template>

<style lang="scss">
.daily-power-market-indication-header {
  display: flex;

  &__position-space {
    min-width: $daily-power-market-position-icon-width;
  }

  &__item {
    flex-grow: 1;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: #535353;
  }
}
</style>
